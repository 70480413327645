import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"floating":"","app":"","hide-overlay":"","temporary":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":require("../assets/sidebar_logo.png"),"alt":"sidebar-logo"}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.username))]),_c(VListItemSubtitle,[_vm._v("Logged In")])],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-home-variant-outline")])],1),_c(VListItemContent,{on:{"click":_vm.home}},[_c(VListItemTitle,[_vm._v("Home")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VListItemContent,{on:{"click":_vm.myAccount}},[_c(VListItemTitle,[_vm._v("My Account")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-send")])],1),_c(VListItemContent,{on:{"click":_vm.sendMessage}},[_c(VListItemTitle,[_vm._v("Send Message")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-reply")])],1),_c(VListItemContent,{on:{"click":_vm.viewMessages}},[_c(VListItemTitle,[_vm._v("View Messages ")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-information")])],1),_c(VListItemContent,{on:{"click":_vm.moreInfo}},[_c(VListItemTitle,[_vm._v("Learn More About Camp")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout")])],1),_c(VListItemContent,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_vm._v("Log Out")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }