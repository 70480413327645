
<template>
  <v-navigation-drawer v-model="drawer" floating app hide-overlay temporary>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="../assets/sidebar_logo.png"  alt="sidebar-logo"/>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{username}}</v-list-item-title>
          <v-list-item-subtitle>Logged In</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-home-variant-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content @click="home">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="myAccount">
          <v-list-item-title>My Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-send</v-icon>
        </v-list-item-icon>

        <v-list-item-content @click="sendMessage">
          <v-list-item-title>Send Message</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-reply</v-icon>
        </v-list-item-icon>

        <v-list-item-content @click="viewMessages">
          <v-list-item-title>View Messages </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="moreInfo">
          <v-list-item-title>Learn More About Camp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="logout">
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideMenu",
  components: {},
  data() {
    return {
      drawer: false
    };
  },
    computed:{
    username(){
      return this.$store.state.authentication.username
    }
  },
  methods: {
    home() {
      this.$router.push({ name: "Home" }).catch(err => {});
    },
    myAccount() {
      this.$router.push({ name: "MyAccount" }).catch(err => {});
    },
    moreInfo() {
      this.$router.push({ name: "MoreInfo" }).catch(err => {});
    },
    sendMessage() {
      this.$router.push({ name: "SendMessage" }).catch(err => {});
    },
    logout() {
      this.$store.dispatch("authentication/logout");
      this.$router.push({ name: "Login" });
    },
    viewMessages() {
      this.$router.push({ name: "ViewMessages" }).catch(err => {});
    },
  }
};
</script>

<style scoped>
</style>
