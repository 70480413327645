import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$store.state.authentication.status >= 1)?_c('div',{attrs:{"id":"header"}},[_c('SideMenu',{ref:"sidemenu"}),_c(VAppBar,{attrs:{"fixed":"","color":"rgba(42, 140, 147,0.9)","dark":"","app":"","src":require("../assets/header_image.jpg"),"collapse-on-scroll":""},scopedSlots:_vm._u([{key:"img",fn:function({ props }){return [_c(VImg,_vm._b({attrs:{"gradient":"to top right, rgba(55,236,186,.7), rgba(25,32,72,.7)"}},'v-img',props,false))]}}],null,false,357078685)},[_c(VBtn,{staticClass:"white--text menu pl-0 pt-2",attrs:{"text":""},on:{"click":_vm.mainMenu}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-menu ")]),_c('div',{staticClass:"desktop pl-3"},[_vm._v(" Menu ")])],1),_c(VSpacer),_c(VToolbarTitle,[_c(VImg,{attrs:{"src":require("../assets/nageela_logo_med.png")},on:{"click":function($event){return _vm.push('Home')}}})],1),_c(VSpacer),_c(VBtn,{staticClass:"desktop",attrs:{"depressed":"","icon":""},on:{"click":_vm.push}},[_c(VIcon,[_vm._v("mdi-home-variant-outline")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }