<template>
  <div v-if="this.$store.state.authentication.status >= 1" id="header">
    <SideMenu ref="sidemenu"/>
    <v-app-bar
        fixed
        color="rgba(42, 140, 147,0.9)"
        dark
        app


        src="../assets/header_image.jpg"
        collapse-on-scroll
    >
      <!--      color="rgba(19,84,122,.5)"-->

      <template v-slot:img="{ props }">
        <v-img
            v-bind="props"
            gradient="to top right, rgba(55,236,186,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <!--            <v-app-bar-nav-icon @click="mainMenu">Menu    <v-icon>mdi-home-variant-outline</v-icon></v-app-bar-nav-icon>-->
      <v-btn
          text
          class="white--text menu pl-0 pt-2"
          @click="mainMenu"
      >

        <v-icon
            dark
        >
          mdi-menu
        </v-icon>
        <div class="desktop pl-3">
          Menu
        </div>

      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-img src="../assets/nageela_logo_med.png" @click="push('Home')"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed icon @click="push" class="desktop">
        <v-icon>mdi-home-variant-outline</v-icon>

      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
import SideMenu from "../components/SideMenu";

export default {
  name: "Header",
  components: {SideMenu},
  data() {
    return {
      collapseOnScroll: true,
    };
  },
  methods: {
    push(pageName) {
      this.$router.push({name: 'Home'}).catch(err => {
      });
    },
    mainMenu() {
      this.$refs.sidemenu.drawer = true;
    }
  }
};
</script>

<style scoped>
.v-responsive v-image logo {
  padding-left: 35%;
}
.v-app-bar.v-toolbar--prominent.v-app-bar--shrink-on-scroll.v-app-bar--is-scrolled:not(.v-app-bar--bottom) .v-toolbar__title{
  padding-bottom: 0;
}
@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

}
</style>
