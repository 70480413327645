<template >
  <div v-if="this.$store.state.authentication.status >= 1">
    <v-footer dark padless>
      <v-card flat tile class="indigo lighten-1 white--text text-center footer">
        <v-card-text></v-card-text>
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          All Rights Reserved | Camp Nageela East | A Project OF JEP Long Island | Affiliation Of National Nageela
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  methods: {
    home() {
      this.$router.push({ name: "Home" }).catch(err => {});
    }
  }
};
</script>

<style scoped>
.v-application--wrap {
  min-height: 1vh !important;
}
.v-application .indigo.lighten-1 {
  width: 100%;
}
</style>
